.footer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-block: 2rem ;
    row-gap: 1rem;
    text-align: center;
    background-color: var(--first-color);
}

.footer-title {
    font-size: var(--h1-font-size);
    color: white;
}

.footer-paragraph {
    color: var(--first-color-lighten);
}

.footer-contact-button {
    background-color: var(--first-color-lighten);
    color: black;
}

.footer-copy {
    text-align: center;
    padding-block: 2rem;
    font-size: var(--small-font-size);
    background-color: var(--first-color-lighten);
}