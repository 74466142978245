.home-figure-section {
    background-color: var(--first-color-lighten);
    padding-block: 4rem;
}

.home-figure-container {
    padding-top: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
}

@media screen and (min-width: 768px) {
    .home-figure-container {
        grid-template-columns: 1fr 1fr;
    }
}