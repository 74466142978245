/*=============== HOME ===============*/
.home-section {
    padding-top: 4rem;
}

.home__container{
    position: relative;
    row-gap: 2rem;
}

.home-slider-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 1rem;
}

.home-image-container {
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: float 4s linear infinite;
}

.home-img {
    height: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 1rem;
    box-shadow: 11px 13px 23.2px 0px rgba(0, 0, 0, .09);
}

@keyframes float{
    0%, 100%{
        transform: translateY(0rem);
    }
    50%{
        transform: translateY(-2rem);
    }
}

.home-swiper-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
}

.home-swiper-slide {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-slide-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
}

.home__title{
    font-size: var(--tertiary-big-font-size);
    margin-bottom: 1rem;
    color: var(--first-color);
    line-height: 2.3rem;
}

.home__description{
    margin-bottom: 1rem;
}

.home__data {
    align-self: center;
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 320px){
    .home__title{
        font-size: var(--h1-font-size);
    }
}

@media screen and (min-width: 500px) {
    .home-image-container {
        height: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
        animation: float 4s linear infinite;
    }
}

@media screen and (min-width: 767px){
    .home__container {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2rem;
    }

    .home-slider-container {
        row-gap: 0;
    }
    
    .home-image-container {
        height: 280px;
        animation: float 4s linear infinite;
    }
    
    .home-swiper-container {
        width: 280px;
    }

    .home-swiper-slide {
        width: 150px;
        height: 150px;
    }
}

@media screen and (min-width: 992px){
    .home-image-container {
        height: 500px;
        animation: float 4s linear infinite;
    }
}

@media screen and (min-width: 1200px){
    .home-section {
    }

    .home__social{
        right: -3rem;
        row-gap: 4.5rem;
    }
    .home__social-follow{
        font-size: var(--small-font-size);
    }
    .home__social-follow::after{
        width: 1.5rem;
        right: -60%;
    }
    .home__social-link{
        font-size: 1.15rem;
    }
}