.service-content {
    padding-top: 2rem;
    margin-block: 3rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
}

.service-content-image {
    position: relative;
}

.service-content-img {
    width: 100%;
}

.service-content-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

/* Apply specific style to the first .service-content */
.service-content:first-of-type {
    grid-template-areas: 
        "image"
        "text";
}

.service-content:first-of-type .service-content-image {
    grid-area: image;
}

.service-content:first-of-type .service-content-info {
    grid-area: text;
}

@media screen and (min-width: 500px) {
    .service-content-image {
        justify-self: center;
    }

    .service-content-img {
        width: 400px;
    }
}

@media screen and (min-width: 768px) {
    .service-content {
        margin-bottom: 3rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    /* Specific styling for larger screens */
    .service-content:first-of-type {
        grid-template-areas: 
            "text image"; /* Text first, image second for larger screens */
    }

    .service-content-img {
        width: 500px;
    }
}
