.contact-container {
    padding-top: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
}

.booking-form {
    border-radius: 1rem;
    padding:1rem;
    border: 1px solid var(--gray-alt);
}

.booking-form-input {
    width:100%;
    padding:.5rem .7rem;
    border-radius: .5rem;
    background:none;
    text-transform: none;
    color:var(--main-color);
    border: 1px solid var(--gray-alt);
    outline: none;
    margin:.7rem 0;
    transition: all .5s ease-in-out;
}

.booking-form-input:focus{
    border:var(--border-hover);
}

.contact-box {
    align-self: center;
}

.contact-data {
    margin-top: .8rem;
    display: grid;
    row-gap: 1rem; /* Maintain the gap between rows */
}

.contact__description{
    display: inline-flex;
    align-items: center;
    column-gap: .5rem;
    color: var(--title-color);
    font-weight: var(--font-medium);
}

.contact__icon{
    font-size: 1.25rem;
    color: var(--first-color);
}

@media screen and (min-width: 767px){
    .contact-section{
        margin-bottom: 8rem;
    }
    .contact-container{
        height: 50vh;
        grid-template-columns: repeat(2, 1fr);
    }
}
