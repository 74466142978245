.scroll_up {
    position: fixed;
    right: 1rem;
    bottom: 2rem;
    background-color: var(--first-color);
    box-shadow: 0 4px 12px hsla(19, 64%, 24%, .1);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: .35rem;
    border-radius: .25rem;
    font-size: 1.1rem;
    color: var(--white-color);
    z-index: var(--z-tooltip);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
    cursor: pointer;
    outline: none;
    border: none;
}

.scroll_up.show {
    opacity: 1;
    visibility: visible;
}

.scroll_up:hover {
    transform: translateY(-.25rem);
}

.scroll_up:focus {
    outline: 2px solid var(--white-color);
    outline-offset: 2px;
}
