/* GOOGLE FONTS ----------------------------------------------------------------------------------------------------- */

/* VARIABLES CSS ---------------------------------------------------------------------------------------------------- */
:root {
    --header-top: 3.6rem;
    --header-height: 4rem;

    /* ---- Colors ---- */
    /*Color mode HSL(hue, saturation, lightness)*/
    --first-color: #D9510B;
    --first-color-alt: #E48554;
    --first-color-lighten: #FBEEE7;
    --second-color: #F6F6DE;
    --second-color-alt: #FAFAEC;
    --second-color-lighten: #F1EDDF;

    /* ---- Black color ---- */
    --black-color: black;
    --black-color-light: hsl(220, 24%, 15%);
    --black-color-lighten: hsl(220, 20%, 18%);

    /* ---- Gray ---- */
    --lith-gray-color: lightgray;
    --dim-gray-color: hsla(0, 0%, 92%, 1);

    --title-color: hsl(19, 16%, 15%);
    --text-color: hsl(19, 16%, 35%);
    --text-color-light: hsl(19, 8%, 55%);
    --body-color: white;
    --container-color: #FFFFFF;


    --white-color: #fff;
   
    --light-red: #E7C8CE;
    --cherry-red: #A6022A;
    --red-color: #e31b23;
    --red-color-alt: #FF4040;
    --cayan-color: #16b8e2;
    --jazzy-color: #A51B4E;
    --ornage-color: #E69500;
    --orange-color-alt: #F3CA80;
    --dark-color: #D78463;
    --Charcoal-color: #36454F	;
    --heart-color: #DC143C;
    --gray-alt: #B3B3B3;
    --gray-lighten: #DFDFDF;
    
    /* ---- Font and typography ---- */
    /*.5rem = 8px | 1rem = 16px ...*/
    --body-font: "Noto Kufi Arabic", sans-serif ;
    --title-font: "Lora", serif;
    --subtitle-font: "Dancing Script", cursive;
    --biggest-font-size: 3rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --h4-font-size: .800rem;
    --normal-font-size: 1rem;
    --small-font-size: .813rem;
    --smaller-font-size: .75rem;

    --primary-big-font-size: 3.5rem;
    --secondary-big-font-size: 3rem;
    --tertiary-big-font-size: 2rem;
    --quaternary-big-font-size: 0.875rem;

    /*= ---- Font weight ---- */
    --font-medium: 500;
    --font-semi-bold: 600;

    /*= ---- Font weight ---- */
    --icon-size: 1.50rem;

    /* ---- z index ---- */
    --z-tooltip: 10;
    --z-fixed: 100;
}

@media screen and (min-width: 768px) {
    :root {

        --normal-font-size: 1.2rem;
        --tertiary-big-font-size: 2.8rem;

        --small-font-size: .875rem;
    }
}

@media screen and (min-width: 1024px) {
    :root {
        --header-top: 2.5rem;
        --header-height: 5rem;


        --biggest-font-size: 5rem;
        --h1-font-size: 2.25rem;
        --h2-font-size: 1.5rem;

        --h3-font-size: 1.50rem;
        --h4-font-size: 1.1rem;
        --smaller-font-size: .813rem;
        --icon-size: 1.5rem;
    }
}

/* --------------------BASE -------------------- */
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    font-size: var(--normal-font-size);
    transition: background 0.4s;
    font-family: var(--body-font), sans-serif;
    background-color: var(--body-color);
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

img {
    max-width: 100%;
    height: auto;
}

/* -------------------- REUSABLE CSS CLASSES -------------------- */
.main {
    position: relative;
    margin-top: calc(var(--header-top) + var(--header-height));
}

.container {
    max-width: 1300px;
    margin-inline: 1rem;
}

.grid {
    display: grid;
}

.section {
    padding-block: 2rem;
}

.section-title {
    font-size: var(--h1-font-size);
    color: black;
    -webkit-text-stroke: .05rem var(--gray-alt);
    text-align: center;
    pointer-events: none;
    position: relative;
}

.section-subtitle {
    display: flex;
    gap: .3rem;
    font-size: var(--h2-font-size);
    font-weight: 700;
    margin-bottom: .5rem;
}

.section-subtitle-icon {
    color: var(--first-color);
}

.section-title-container{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    margin-bottom: 1.5rem;
}

.primary-section-title {
    font-size: var(--h1-font-size);
    font-family:"Merienda", cursive ;

    color: black;
    text-align: center;
    font-weight: 700;

}

.secondary-section-title {
    font-size: var(--h1-font-size);
    font-family:"Merienda", cursive ;
    text-align: center;
    font-weight: 700;
    color: var(--ornage-color);
}

.primary-small-title {
    font-size: var(--smaller-font-size);
    color: var(--white-color);
}

.secondary-small-title {
    font-size: var(--smaller-font-size);
    color: var(--black-color);
    margin-bottom: 1.5rem;
}

:root{
    --main-color:#443;
    --border-radius:95% 4% 97% 5%/4% 94% 3% 95%;
    --border-radius-hover:4% 95% 6% 95%/95% 4% 92% 5%;
    --border:.2rem solid var(--main-color);
    --border-hover: 1px dashed var(--main-color);
}

/* ---- BUTTON ---- */
.button {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: .5rem;
    width: max-content;
    border: none;
    outline: none;
    padding: 1rem 1.5rem;
    font-size: var(--normal-font-size);
    border-radius: .5rem;
    color:var(--white-color);
    font-weight: var(--font-medium);
    background-color: var(--first-color);
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.button:hover {
    background-color: var(--first-color-alt);
}


/* -------------------- SCROLL BAR -------------------- */
::-webkit-scrollbar {
    width: .2rem;
    height: .2rem;
    background-color: hsl(19, 8%, 75%);
}

::-webkit-scrollbar-thumb {
    background-color: hsl(19, 8%, 65%);
}

::-webkit-scrollbar-thumb:hover {
    background-color: hsl(19, 8%, 55%);
}

/* ---- BREAKPOINTS ---- */
/* FOR SMALL DEVICES */
@media screen and (max-width: 340px) {
    .container {
        margin-inline: 1rem;
    }
}


/* ---- For large devices ---- */
@media screen and (min-width: 768px) {
    .section {
        padding-block: 3rem 2rem;
    }
}

@media screen and (min-width: 1024px){
    .container {
        margin-inline: auto;
        padding-inline: 1.5rem;
    }
}