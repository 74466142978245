/*=============== HEADER ===============*/
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: var(--z-fixed);
    background-color: var(--first-color-lighten);
}

/* HEADER TOP ------------------------------------------------------------------------------------------------------- */
.header-top {
    padding-block: .5rem;
    font-size: var(--small-font-size);
    opacity: 1;
    visibility: visible;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    text-align: center;
    gap: .4rem;
    border-bottom: 1px solid var(--white-color);
    transition: height 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
}

.header-top.hidden {
    height: 0;
    padding: 0;
    opacity: 0;
    visibility: hidden;
}

.language-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    column-gap: .3rem;
    width: max-content;
}

.languageSelector label,
.languageSelector select,
.languageSelector span {
    margin-right: 10px;
    /* Adjust spacing between elements */
}

.languageSelector select {
    border: 1px solid #ccc;
    outline: none;
}

.language-option {
    border: 1px solid #ccc;
    background-color: var(--white-color);
}

.languageSelector select {
    flex: 1;
}

.flag-img {
    width: 15px;
    height: 15px;
}



.ht_address-icon,
.ht_time-icon {
    font-size: var(--small-font-size);
}

.header-top-address {
    font-size: var(--small-font-size);
    justify-self: self-end;
}

.header-top-flag {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 460px) {
    .header-top {
        padding-block: .8rem;
        justify-content: space-between;
    }

    .language_selector {
        grid-column: 3;
        grid-row: 1;
    }
}

/* NAV =========================================================================================================*/
.nav {
    height: var(--header-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav__burger,
.nav__close {
    color: var(--white-color);
    background-color: red;
    padding: .3rem .3rem;
    border-radius: 0.4rem;
    font-size: 1.8rem;
}

.nav__close {
    position: absolute;
    right: 1rem;
    top: 1rem;
}

.nav-logo-container {
    display: inline-flex;
    align-items: center;
}

.nav-logo-container .nav-logo-img {
    width: 150px;
}

.nav__toggle {
    position: relative;
    width: 32px;
    height: 32px;
}

.nav__burger {
    cursor: pointer;
    transition: opacity .1s, transform .4s;
}

/* Navigation for mobile devices */
@media screen and (max-width: 1118px) {
    .nav__menu {
        position: absolute;
        left: 0;
        top: 0;
        width: 80%;
        pointer-events: none;
        opacity: 0;
        transition: top .4s, opacity .3s;
        max-height: 90vh; /* Adjust as needed */
        overflow-y: auto;
    }

    .nav__list {
        background-color: var(--black-color);
        padding-block: 2rem 1rem;
        overflow: scroll;
    }
}

.nav__list {
    position: relative;
}

.nav__link {
    color: var(--white-color);
    font-weight: var(--font-semi-bold);
    padding: 1.25rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color .3s;
}

.nav__link:hover {
    color: var(--first-color);
}

.nav__link.active {
    color: var(--first-color);
}

.dropdown-nav-link {
    align-items: center;
    justify-content: space-between;
    display: flex;
}

/* dropdown nav icon */
.nav-link-icon {
    display: block;
}

/* Show menu */
.show-menu {
    opacity: 1;
    pointer-events: initial;
}

/* DROPDOWN */
.dropdown__item {
    cursor: pointer;
}

.dropdown__arrow {
    font-size: 1.25rem;
    font-weight: initial;
    transition: transform .4s;
}

.dropdown__link,
.dropdown__sublink {
    padding: 1.25rem 1.25rem 1.25rem 2.5rem;
    color: var(--black-color);
    background-color: var(--white-color);
    box-shadow: 11px 13px 23.2px 0px rgba(0, 0, 0, 0.07);
    display: flex;
    align-items: center;
    column-gap: .5rem;
    font-weight: var(--font-semi-bold);
    transition: background-color .3s;
}

.dropdown__link i,
.dropdown__sublink i {
    font-size: 1.25rem;
    font-weight: initial;
}

.dropdown__link:hover,
.dropdown__sublink:hover {
    background-color: var(--lith-gray-color);
}

.dropdown__link.active {
    background-color: var(--lith-gray-color);
}

.dropdown__menu,
.dropdown__submenu {
    max-height: 0;
    overflow: hidden;
    transition: max-height .4s ease-out;
}

/* Show dropdown menu & submenu */
.dropdown__item:hover .dropdown__menu,
.dropdown__subitem:hover > .dropdown__submenu {
    max-height: 1000px;
    transition: max-height .4s ease-in;
}

/* Rotate dropdown icon */
.dropdown__item:hover .dropdown__arrow {
    transform: rotate(180deg);
}

/*=============== DROPDOWN SUBMENU ===============*/
.dropdown__add {
    margin-left: auto;
}

.dropdown__sublink {
    background-color: var(--black-color-lighten);
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 340px) {

    .nav__link {
        padding-inline: 1rem;
    }
}
/* For large devices */
@media screen and (min-width: 768px) {
    .nav-logo-container .nav-logo-img {
        width: 160px;
    }
}

@media screen and (min-width: 1118px) {
    .nav-logo-container .nav-logo-img {
        width: 200px;
    }

    .nav {
        display: flex;
        justify-content: space-between;
    }

    .nav__menu {
        height: 100%;
    }

    .nav__toggle {
        display: none;
    }

    .nav__close {
        display: none;
    }

    .nav__list {
        height: 100%;
        display: flex;
        column-gap: 3rem;
    }

    .nav__link {
        height: 100%;
        padding: 0;
        justify-content: initial;
        column-gap: .25rem;
        color: var(--black-color)
    }

    .nav__link:hover {
        background-color: transparent;
    }

    .nav-link-icon {
        display: none;
    }

    /* dropdown ----------------------------*/
    .dropdown__item,
    .dropdown__subitem {
        position: relative;
    }

    .dropdown__menu,
    .dropdown__submenu {
        max-height: initial;
        overflow: initial;
        position: absolute;
        left: 0;
        opacity: 0;
        pointer-events: none;
        top: var(--header-height);
        width: max-content;
        transition: opacity .3s, top .3s;
    }

    .dropdown__link,
    .dropdown__sublink {
        padding-inline: 1rem 3.5rem;
    }

    .dropdown__subitem .dropdown__link {
        padding-inline: 1rem;
    }

    .dropdown__submenu {
        position: absolute;
        left: 100%;
        top: .5rem;
    }

    /* Show dropdown menu */
    .dropdown__item:hover .dropdown__menu {
        opacity: 1;
        pointer-events: initial;
        transition: top .3s;
    }

    /* Show dropdown submenu */
    .dropdown__subitem:hover > .dropdown__submenu {
        opacity: 1;
        top: 0;
        pointer-events: initial;
        transition: top .3s;
    }
}