.about-container {
    display: grid;
    padding-top: 2rem;
}

.about-content {
    justify-self: center;
    align-self: center;
    display: grid;
    grid-template-columns: 1fr;
}

.about-image-container {
    width: 280px;
    margin: auto;
}

.about-image {
    width: 100%;
    animation: about-animation 10s linear infinite;
}

.about-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding-top: 3rem;
}

.about-card {
    flex:1 1 10rem;
    padding:1rem;
    text-align: center;
    border: 2px solid #000000;
    border-radius: .5rem;
}

.about-card-img {
    height: 55px;
}

.about-card-title {
    font-size: var(--normal-font-size);
}
@keyframes about-animation {
    0%, 100%{
        transform: scale(.9);
    }
    50%{
        transform: scale(.8);
    }
}

@media screen and (min-width: 500px) {
    .about-image-container {
        width: 400px;
        margin: auto;
    }
}

@media screen and (min-width: 768px) {
    .about-content {
        column-gap: 1.5rem;
    }

    .about-card-img {
        height: 60px;
    }
}

@media screen and (min-width: 900px) {
    .about-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (min-width: 1200px) {
    .about-image-container {
        width: 650px;
    }
}

