.location-main {
    background-color: var(--first-color-lighten);
}

.hero-section {
    text-align: center;
    height: 25vh;
    background-color: var(--first-color-alt);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.hero-data {
    backdrop-filter: blur(9);
    background-color: rgba(222, 222, 222, .9);
    padding: 1.5rem;
    color: var(--first-color);
    font-weight: bold;
}


/* About Box ---------------------------------------------------------------------------------- */
.location-container {
    padding-block: 7rem;
    display: grid;
    gap: 3rem;
}

.about-box-content {
    align-self: center;
}

.about-box-list {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    gap: 2rem;
}

.about-box {
    height: 50px;
    width: 70px;
}

.about-box-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.about-box-paragraph {
    font-size: var(--small-font-size);
}

@media screen and (min-width: 500px) {
    .about-box {
        height: 55px;
        width: 85px;
    }
}

@media screen and (min-width: 768px) {
    .location-container {
        padding-top: 2rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
    .about-box-content {
        justify-self: initial   ;
    }

}






















